<app-loader *ngIf="showLoader"></app-loader>
<app-countdown-timer [duration]="900" (timeUp)="handleCountdownTimerUp()"></app-countdown-timer>
<div class="resume-column">
  <app-shopcar-resume></app-shopcar-resume>
  <div class="payment-block">
    <p class="title">{{"PAYMENTS.PAYMENTS_ACCEPT" | translate}}</p>
    <div class="payments-methods">
      <img src="assets/payments/visa.svg" alt="">
      <img src="assets/payments/mastercard.svg" alt="">
      <img src="assets/payments/paypal.svg" alt="">
    </div>
  </div>
</div>
<div class="interaction-box">
  <app-breadcrumb></app-breadcrumb>
  <h1 class="section-title">{{"PAYMENTS.PAYMENT_TITLE" | translate}}</h1>
  <h2 style="font-size: 14px;">{{"PAYMENTS.PAYMENT_SUBTITLE" | translate}}</h2>
  <h1>
    {{reservationId}}
  </h1>
  <h2 class="text-icon-layout section-subtitle section-subtitle--green">
    <mat-icon fontIcon="lock"></mat-icon>
    <span class="section-disclaimer">{{"PAYMENTS.PAYMENT_TRUSTLY" | translate}}</span>
  </h2>

  <!-- <ng-container *ngIf="reservation$ | async as reservation"> -->
    <div class="info-disclaimer">
      <!-- <span class="section-title">{{ reservation.clientName }}</span> -->
      <!-- <span class="section-title">{{ reservation.clientEmail }}</span> -->
      <small class="section-subtitle" style="font-size: .8rem;">{{"CHECKOUT.CONFIRMATION_PAYMENT.CONFIRMATION_TEXT" | translate}}</small>
      <small class="section-subtitle" style="font-size: .8rem;">{{"CHECKOUT.CONFIRMATION_PAYMENT.CONFIRMATION_TEXT_2" | translate}}</small>
      <!-- <app-terms-conditions-form (onValid)="onValidTermsAndConditions($event)"></app-terms-conditions-form> -->
    </div>
  <!-- </ng-container> -->

  <mat-accordion *ngIf="showPayments">
    <ng-container *ngIf="banks$ | async as bankList">
      <ng-container *ngFor="let bank of bankList">

        <mat-expansion-panel hideToggle (opened)="paymentMethodOpened.set(bank.type)" (closed)="paymentMethodOpened.set(null)" [expanded]="paymentGatewaySignal() === paymentMethods.PAYPAL" *ngIf="bank.status && bank.type === paymentMethods.PAYPAL && !paypalLoading()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-radio-button value="1" [checked]="paymentMethodOpened() == bank.type"></mat-radio-button>
              <span class="title">{{'CHECKOUT.PAYMENT_AREA.PAYPAL_LABEL' | translate}}</span>
              <div class="payments-methods" style="flex: 1;margin-left: 20px;">
                <img src="assets/payments/visa.svg" alt="">
                <img src="assets/payments/mastercard.svg" alt="">
                <img src="assets/payments/american-express.svg" alt="">
                <img src="assets/payments/paypal.svg" alt="">
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <small style="margin-bottom: 15px;display: block;text-align: center; font-weight: 600;">
            {{'CHECKOUT.PAYMENT_AREA.PAYPAL_SUBTITLE' | translate}}
          </small>
          <app-paypal [reservationId]="reservationId" [bankId]="bank.id" [clientId]="paypalClient()"></app-paypal>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle (opened)="paymentMethodOpened.set(bank.type)" (closed)="paymentMethodOpened.set(null)" [expanded]="paymentGatewaySignal() === paymentMethods.MIT" *ngIf="bank.status && bank.type === paymentMethods.MIT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-radio-button value="1" [checked]="paymentMethodOpened() == bank.type"></mat-radio-button>
              <span class="title">{{'CHECKOUT.PAYMENT_AREA.DEBIT_CREDIT_CARD_LABEL' | translate}}</span>
              <div class="payments-methods" style="flex: 1;margin-left: 20px;">
                <img src="assets/payments/visa.svg" alt="">
                <img src="assets/payments/mastercard.svg" alt="">
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <small style="margin-bottom: 15px;display: block;text-align: center; font-weight: 600;">
            {{'CHECKOUT.PAYMENT_AREA.DEBIT_CREDIT_CARD_SUBTITLE' | translate}}
          </small>
          <ng-container *ngIf="showPaymentError">
            <div class="disclaimer-layout margin-border">
              <span class="text--error">{{'PAYMENTS.PAYMENT_ERROR' | translate}}</span>
            </div>
          </ng-container>
          <app-mit [url]="mitpayment"></app-mit>
        </mat-expansion-panel>

        <!--
        <mat-expansion-panel hideToggle (opened)="paymentMethodOpened.set(bank.type)" (closed)="paymentMethodOpened.set(null)" [expanded]="paymentGatewaySignal() === paymentMethods.CARD" *ngIf="bank.status && bank.type === paymentMethods.CARD">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-radio-button value="1" [checked]="paymentMethodOpened() == bank.type"></mat-radio-button>
              !-- <span class="title">{{'CHECKOUT.PAYMENT_AREA.DEBIT_CREDIT_CARD_LABEL' | translate}}</span> --
              <span class="title">PLUGNPAY</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngIf="showPaymentError">
            <div class="disclaimer-layout margin-border">
              <span class="text--error">{{'PAYMENTS.PAYMENT_ERROR' | translate}}</span>
            </div>
          </ng-container>
          <app-plugnpay [reservationId]="reservationId" [bankId]="bank.id" (onSuccess)="onPaymentSucces($event)" (onError)="onPaymentError($event)"></app-plugnpay>
        </mat-expansion-panel>
        -->

        <mat-expansion-panel hideToggle (opened)="paymentMethodOpened.set(bank.type)" (closed)="paymentMethodOpened.set(null)" [expanded]="paymentGatewaySignal() === paymentMethods.FRON_DESK" *ngIf="bank.status && bank.type === paymentMethods.FRON_DESK">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-radio-button value="1" [checked]="paymentMethodOpened() == bank.type"></mat-radio-button>
              <span class="title">{{'CHECKOUT.PAYMENT_AREA.FRONT_DESK_LABEL' | translate}}</span>
              <div class="payments-methods">
                <img src="assets/payments/visa.svg" alt="">
                <img src="assets/payments/mastercard.svg" alt="">
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngIf="showPaymentError">
            <div class="disclaimer-layout margin-border">
              <span class="text--error">{{'PAYMENTS.PAYMENT_ERROR' | translate}}</span>
            </div>
          </ng-container>
          <app-front-desk [reservationId]="reservationId" [bankId]="bank.id" (onSuccess)="onPaymentSuccessFrontDesk()"></app-front-desk>
        </mat-expansion-panel>

      </ng-container>
    </ng-container>
  </mat-accordion>
</div>
